import { useEffect, useState } from "react";
import { GameBoard } from "./game/gameboard.js";
import { GameNavigation } from "./campaign_level_navigation.js";
import { parseLevelCSVLine } from "./game/level_util.js";
import { useStickyState } from "../utils/sticky_state";
import {
    getLevelComplete,
    setLevelComplete,
} from "../utils/game_score_tracking";
import { fetchCsv } from "../utils/csv_util.js";
import { DiscordButton, SettingsButton, SvgStar } from "../utils/svg_util.js";
import { DrawingUtil } from "./game/game_state.js";
import { getSettingValue, Settings } from "./settings.js";
import { LevelFilter } from "./level_filter.js";
import {
    isStringUuidv4,
    setResultsOnTheServer,
    synchronizeResults,
} from "../utils/score_sync_util.js";

const save_version = "2022-12-30";

export function CampaignContents(props) {
    const [currentLevel, setCurrentLevel] = useStickyState(
        null,
        "currentLevel_" + save_version
    );
    const [lastLevel, setLastLevel] = useStickyState(
        null,
        "lastLevel_" + save_version
    );

    const [levelList, setLevelList] = useState(null);

    const [unlockedLimit, setUnlockedLimit] = useState(10);

    const [showLevelsWithScore, setShowLevelsWithScore] = useStickyState(
        { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true },
        "levelScoreFilterx" + save_version
    );

    const [levelsLastAccess, setLevelsLastAccess] = useStickyState(
        {},
        "levelsLastAccess_" + save_version
    );

    const [levelsURL, setLevelsURL] = useState(null);

    const levelsKey = "Main Campaign";

    const [filteredLevelList, setFilteredLevelList] = useState(null);

    const [totalScore, setTotalScore] = useState(-1);
    const [unlocked, setUnlocked] = useState(0);

    var colors = null;
    if (getSettingValue("colorScheme", "") === "HC") {
        colors = ["white", "black", "blue"];
    }

    // handle get request parameters
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        var levelParam = params.get("level");
        var levellistParam = params.get("levellist");

        if (levellistParam) {
            setLevelsURL(levellistParam);
        } else {
            setLevelsURL("levels/featured.csv");
        }
        if (levelParam) {
            console.log(levelParam, typeof levelParam);
            var level = parseLevelCSVLine(levelParam.replaceAll("_", " "));
            if (level) {
                setCurrentLevel(level);
            }
        } else {
            // if currentLevel is not on the list of current all levels then set level to null
            if (currentLevel && levelList) {
                var found = false;
                for (var i = 0; i < levelList.length; i++) {
                    if (levelList[i].hash == currentLevel.hash) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    setCurrentLevel(null);
                }
            }
        }

        if (params.get("c4d4e4")) {
            setUnlockedLimit(1000);
        }
    }, []);

    useEffect(() => {
        if (levelList == null) return;
        const params = new URLSearchParams(window.location.search);
        var levelParam = parseInt(params.get("level"));
        if (levelParam && levelParam >= 1 && levelParam <= levelList.length) {
            var level = levelList[levelParam - 1];
            setCurrentLevel(level);
        }
    }, [levelList]);

    useEffect(() => {
        const drawing = new DrawingUtil();
        drawing.preloadImages();
    }, []);

    useEffect(() => {
        var key = "totalScore_" + levelsKey;
        if (totalScore > 0) {
            localStorage.setItem(
                key,
                JSON.stringify([totalScore, levelList.length])
            );
        }
    }, [totalScore]);

    useEffect(() => {
        if (!currentLevel) return;
        var new_last_access = { ...levelsLastAccess };
        new_last_access[currentLevel.hash] = new Date();
        setLevelsLastAccess(new_last_access);
        setLastLevel(currentLevel);
    }, [currentLevel]);

    function fetchAndSetLevels(url) {
        console.log("fetchAndSetLevels", url);
        if (!url) return;
        fetchCsv(url).then((csv) => {
            var lines = csv.split(/\r\n|\n/);
            //iterate over all lines
            var levels = [];
            for (var i = 0; i < lines.length; i++) {
                //lines.length; i++) {
                var line = lines[i];
                if (line) {
                    //parse line
                    var level = parseLevelCSVLine(line);
                    level.number = i;
                    //add level to levels
                    levels.push(level);
                }
            }
            setLevelList(levels);
        });
    }

    useEffect(() => {
        // if (levelList == null) {
        fetchAndSetLevels(levelsURL);
        // }
    }, [levelsURL]); //intentioanlly empty

    useEffect(() => {
        if (currentLevel != null) return;
        const element = document.getElementById("scroll_to_me");
        if (element) {
            element.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
            });
        }
    }, [currentLevel, lastLevel]);

    useEffect(() => {
        listFilteredLevels();
    }, [levelList, showLevelsWithScore]);

    function handleClickLevel(level) {
        setCurrentLevel(level);
    }

    function handleMenuClick() {
        setCurrentLevel(null);
    }

    function listFilteredLevels() {
        var new_levels = [];
        if (levelList == null) return;
        var total_score = 0;
        var zero_levels = levelList
            .filter((level) => {
                return getLevelComplete(level).score == 0;
            })
            .map((level) => level.hash);

        var unlocked_level_limit = unlockedLimit;
        var unlocked = !showLevelsWithScore[0]
            ? 0
            : Math.max(0, zero_levels.length - unlocked_level_limit);

        var good_zero_levels = zero_levels.slice(0, unlocked_level_limit);

        for (var i = 0; i < levelList.length; i++) {
            var level = levelList[i];
            var is_complete = getLevelComplete(level);
            total_score += is_complete.score;
            if (showLevelsWithScore[is_complete.score]) {
                if (
                    is_complete.score > 0 ||
                    good_zero_levels.includes(level.hash)
                ) {
                    new_levels.push({
                        score: is_complete.score,
                        level: level,
                    });
                }
            }
        }
        // console.log("total_score", total_score);
        setUnlocked(unlocked);
        setTotalScore(total_score);
        setFilteredLevelList(new_levels);
    }

    function handleNextLevelClick(level) {
        //index of current level

        var last_level = null;
        for (var i = 0; i < levelList.length; i++) {
            if (levelList[i].hash === level.hash) {
                last_level = level;
                break;
            }
        }
        var new_level = null;
        for (var i = 0; i < filteredLevelList.length; i++) {
            if (filteredLevelList[i].level.number > last_level.number) {
                new_level = filteredLevelList[i].level;
                break;
            }
        }
        setCurrentLevel(new_level);
    }

    function handleLevelComplete(level, moves) {
        const shouldSync = getSettingValue("synchronizeResults", 0);
        if (shouldSync == 1) {
            var key = getSettingValue("synchronizeSessionKey", "");
            var email = getSettingValue("synchronizeSessionEmail", "");
            if (key && isStringUuidv4(key)) {
                setResultsOnTheServer(key, email, [
                    { code: level.hash, moves: moves },
                ]);
            }
            console.log("synced one level");
        }
        setLevelComplete(level, moves);
        listFilteredLevels();
    }

    if (currentLevel) {
        return (
            <GameBoard
                level={currentLevel}
                handleMenuClick={handleMenuClick}
                handleNextLevelClick={handleNextLevelClick}
                handleLevelComplete={handleLevelComplete}
            ></GameBoard>
        );
    } else {
        return (
            <main className="main">
                <header className="header">
                    <h1 className="header__logo">Kuboble</h1>
                </header>
                <SettingsButton onClick={props.settingsButtonOnClick} />
                {totalScore > 50 ? <DiscordButton /> : ""}
                <article
                    className="level"
                    onDragStart={() => false}
                    onDrop={() => false}
                >
                    <LevelFilter
                        showLevelsWithScore={showLevelsWithScore}
                        setShowLevelsWithScore={setShowLevelsWithScore}
                    ></LevelFilter>

                    <GameNavigation
                        levels={filteredLevelList}
                        handleClick={handleClickLevel}
                        currentLevel={lastLevel}
                        unlocked={unlocked}
                        colors={colors}
                    ></GameNavigation>
                </article>

                <footer>Kuboble 2023</footer>
            </main>
        );
    }
}
