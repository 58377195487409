import React, { Children } from "react";
import { BackButton } from "../utils/svg_util";

function Blog(props) {
    return (
        <main className="main blog_body">
            <h1>Kuboble Updates: </h1>

            <BlogEntry title="Update 1.3.2023">
                <p>Add ability to synchronize progress between devices.</p>
            </BlogEntry>

            <BlogEntry title="Update 1.3.2023">
                <p>
                    Made horizontal/vertical level orientation configurable for
                    better experience when playing on multiple devices.
                </p>
            </BlogEntry>

            <BlogEntry title="Update 27.2.2023">
                <p>
                    Added this log to the game.
                    <br />- Some user-friendly tricks in keyboard controls
                    <br />- Added progress info / ranks
                </p>
            </BlogEntry>

            <BlogEntry title="Update 14.2.2023 (bugfixes)">
                <p>Fixed a problem where with cpu overutilization.</p>
            </BlogEntry>

            <BlogEntry title="Updates 14.2.2023 (new settings)">
                <p>
                    Added keyboard controls and some new settings:
                    <br />- Game sounds
                    <br />- High contrast colors
                    <br />
                    - Active piece highlighting for keyboard controls
                    <br />
                </p>
            </BlogEntry>

            <BlogEntry title="Hello World!">
                <p>
                    With{" "}
                    <a href="https://news.ycombinator.com/item?id=34706098">
                        the post on Hacker News
                    </a>{" "}
                    the game is now public and has it's first players outside of
                    my friends and family circle.
                </p>
                <p> Have fun!</p>
                <p>Contact me: kuboble (at) kuboble (.) com</p>
                <p>All rights reserved.</p>
            </BlogEntry>
        </main>
    );
}

function BlogEntry(props) {
    return (
        <div className="blog_entry">
            <h1>{props.title}</h1>
            <h3>{props.subtitle}</h3>
            {props.children}
        </div>
    );
}
export { Blog, BlogEntry };
