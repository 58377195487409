function getKey(level) {
    if (typeof level === "string") {
        return level;
    } else if (level.hash) {
        return level.hash;
    } else {
        throw new Error("Can't get level hash from the variable ", level);
    }
}

function getScore(level, moves) {
    let score = 5 + level.opti - moves;
    if (score < 1) {
        return 1;
    }
    if (score > 5) {
        return 5; //should not happen
    }
    return score;
}

export function setLevelComplete(level, number_of_moves) {
    const key = getKey(level);
    let current = getLevelComplete(level);
    if (current.moves && current.moves < number_of_moves) {
        //nothing to do - a better result is already in the localstorage
        return;
    }
    let result = {
        moves: number_of_moves,
    };
    localStorage.setItem(key, JSON.stringify(result));
}

export function getLevelComplete(level) {
    const key = getKey(level);
    let result = localStorage.getItem(key);

    if (result) {
        try {
            var parsed = JSON.parse(result);
            return {
                moves: parsed.moves,
                score: getScore(level, parsed.moves),
            };
        } catch (e) {
            //delete entry from localstorage
            // localStorage.removeItem(key);
            return { moves: null, score: 0 };
        }
    }
    return { moves: null, score: 0 };
}

export function getAllLevelsComplete() {
    let result = [];
    for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        if (key.length == 64) {
            let level = JSON.parse(localStorage.getItem(key));
            if (level.moves && level.moves > 0) {
                result.push({ code: key, moves: level.moves });
            }
        }
    }
    return result;
}
