export function SvgStar(props) {
    const points = [
        44.64, -14.5, -27.59, 37.97, 0, -46.94, 27.59, 37.97, -44.64, -14.5,
    ];

    const shift = (ps, i) => {
        return ps.map((p, j) => (j % 2 == 0 ? p + 100 * i + 50 : p + 50));
    };
    var color = [];
    for (var i = 1; i <= props.total; i++) {
        color.push("black");
    }
    for (var i = 1; i <= props.score; i++) {
        color[i - 1] = "gold";
    }
    const n = props.total ?? 5;
    var viewBoxWidth = n * 100;
    var viewBoxHeight = 100;
    return (
        <svg
            height={props.height ?? 19}
            width={props.width}
            id="stars-icon"
            viewBox={[0, 0, viewBoxWidth, viewBoxHeight]}
        >
            {/* {[0, 1, 2, 3, 4].map((i) => {
                return (
                    <polygon
                        key={i}
                        points={shift(mult(points, 1.1), i).join(" ")}
                        fill="black"
                    />
                );
            })} */}
            {[...Array(n).keys()].map((i) => {
                return (
                    <polygon
                        key={i}
                        points={shift(points, i).join(" ")}
                        fill={color[i]}
                    />
                );
            })}
            {props.striked && (
                <line
                    x1="-44"
                    y1="-20"
                    x2="444"
                    y2="20"
                    stroke="red"
                    strokeWidth="10"
                />
            )}
        </svg>
    );
}

export function DiscordButton(props) {
    return (
        <button
            onClick={() => {
                window.open("https://discord.gg/cUKUYh8Ajh", "_blank");
            }}
            className="topMenuButton discordButton"
            target="_blank"
            rel="noreferrer"
        >
            <svg
                // className="discord__img"
                width="40"
                height="31"
                viewBox="0 -1 41 32"
                fill="none"
            >
                <path
                    d="M34.7082 2.56973C32.095 1.38031 29.2928 0.504004 26.3628 0.0021054C26.3095 -0.00758109 26.2562 0.016626 26.2287 0.0650413C25.8683 0.700885 25.4691 1.53039 25.1895 2.18239C22.0382 1.71439 18.903 1.71439 15.8163 2.18239C15.5367 1.5159 15.123 0.700885 14.761 0.0650413C14.7335 0.0182413 14.6802 -0.00596572 14.6268 0.0021054C11.6985 0.5024 8.89625 1.37871 6.28143 2.56973C6.25879 2.57941 6.23939 2.59557 6.22651 2.61653C0.911211 10.4937 -0.544873 18.1772 0.169433 25.7655C0.172665 25.8027 0.193674 25.8382 0.222763 25.8607C3.72965 28.4154 7.12667 29.9664 10.4606 30.9944C10.514 31.0105 10.5705 30.9911 10.6044 30.9475C11.3931 29.8792 12.0961 28.7528 12.6989 27.5682C12.7344 27.4988 12.7005 27.4165 12.6278 27.389C11.5127 26.9694 10.4509 26.4578 9.42954 25.8769C9.34875 25.8301 9.34228 25.7155 9.4166 25.6606C9.63154 25.5008 9.84653 25.3346 10.0518 25.1668C10.0889 25.1361 10.1406 25.1296 10.1843 25.149C16.8942 28.1879 24.1585 28.1879 30.7892 25.149C30.8329 25.128 30.8846 25.1345 30.9234 25.1652C31.1286 25.333 31.3436 25.5008 31.5601 25.6606C31.6344 25.7155 31.6296 25.8301 31.5488 25.8769C30.5274 26.4691 29.4657 26.9694 28.349 27.3874C28.2763 27.4149 28.2439 27.4988 28.2795 27.5682C28.8952 28.7511 29.5982 29.8776 30.3723 30.9459C30.4046 30.9911 30.4628 31.0105 30.5161 30.9944C33.8662 29.9664 37.2632 28.4154 40.7701 25.8607C40.8008 25.8382 40.8202 25.8043 40.8235 25.7671C41.6783 16.9942 39.3916 9.3737 34.7615 2.61813C34.7502 2.59557 34.7309 2.57941 34.7082 2.56973ZM13.7009 21.1451C11.6807 21.1451 10.0162 19.3053 10.0162 17.0459C10.0162 14.7865 11.6484 12.9468 13.7009 12.9468C15.7694 12.9468 17.4178 14.8027 17.3855 17.0459C17.3855 19.3053 15.7532 21.1451 13.7009 21.1451ZM27.3244 21.1451C25.3043 21.1451 23.6397 19.3053 23.6397 17.0459C23.6397 14.7865 25.2719 12.9468 27.3244 12.9468C29.393 12.9468 31.0413 14.8027 31.0091 17.0459C31.0091 19.3053 29.393 21.1451 27.3244 21.1451Z"
                    fill="#5865F2"
                />
            </svg>
        </button>
    );
}

export function SettingsButton(props) {
    return (
        <button onClick={props.onClick}>
            <svg
                className="topMenuButton settingsButton"
                width="20"
                height="20"
                viewBox="-3 -10 74 74"
            >
                <path d="M0 0h48v48h-48z" fill="none" />
                <path d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97l-.75-5.3c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97l-4.98-2.01c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31c-.08.64-.14 1.29-.14 1.95s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zm-14.86 5.05c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
                <rect width="10" height="20" x="50" y="30" />
                <circle cx="55" cy="20" r="5" strokeWidth="3" />
            </svg>
        </button>
    );
}

// todo: for now it's just settings button but I want to make it a left arrow later
export function BackButton(props) {
    return (
        <button onClick={props.onClick}>
            <svg
                className="topMenuButton settingsButton"
                width="200"
                height="200"
                viewBox="0 0 200 200 "
            >
                <path d="M 10 100 L 80 50 V 80 H 170 V 120 H 80 V 150" />
            </svg>
        </button>
    );
}

export function InfoButton(props) {
    return (
        <button onClick={props.onClick}>
            <svg
                className="topMenuButton infoButton"
                width="20"
                height="20"
                viewBox="0 0 250 250 "
            >
                <rect width="50" height="100" x="100" y="100" />
                <circle cx="125" cy="50" r="25" strokeWidth="3" />
            </svg>
        </button>
    );
}

export function MirronBallComponent(props) {
    var fill;

    if (props.color == "orange") {
        fill = ["#FF956B", "#fe703a", "#e65d26", "#eb7143"];
    } else if (props.color == "blue") {
        fill = ["#5c97ec", "#2672e6", "#1b59b6", "#1b59b6"];
    } else if (props.color == "green") {
        fill = ["#40b240", "#009a01", "#018001", "#279426"];
    } else if (props.color == "gray") {
        fill = ["#b2b2b2", "#9a9a9a", "#808080", "#949494"];
    } else if (props.color == "white") {
        fill = ["#f2f2f2", "#cacaca", "#b0b0b0", "#c4c4c4"];
    } else if (props.color == "black") {
        fill = ["#424242", "#3a3a3a", "#202020", "#343434"];
    }

    return (
        <svg width="20" height="20" viewBox="-2 -2 23 23">
            <path d="M0.5,10.5 a10,10 0 0,1 20.5,0" fill={fill[0]} />
            <path d="M0.5,10.5 a10,10 0 0,0 20.5,0" fill={fill[3]} />
            <path d="M2.5,10.5   a7,7 0 0,1 16.5,0" fill={fill[1]} />
            <path d="M2.5,10.5   a7,7 0 0,0 16.5,0" fill={fill[2]} />
        </svg>
    );
}

export function VerticalBoardItem(props) {
    return (
        <svg
            width={props.x}
            height={props.y}
            viewBox={[-3, -3, props.x - 3, props.y - 3]}
        >
            <rect width={props.x} height={props.y} x="0" y="0" />
        </svg>
    );
}
