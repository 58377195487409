import { Spinner } from "react-bootstrap";
import { SvgBalls, SvgStar } from "../utils/canvas_util";
import { MirronBallComponent } from "../utils/svg_util";
import { getNumberBalls } from "./game/level_util";

function GameNavigation(props) {
    if (props.levels === null) {
        return <Spinner></Spinner>;
    }
    var levels = props.levels.map((level, i) => {
        var className =
            level.level.hash == props.currentLevel?.hash
                ? "level__link level__link__current"
                : "level__link";
        var id =
            level.level.hash == props.currentLevel?.hash ? "scroll_to_me" : "";

        var balls = getNumberBalls(level.level.level);
        var dim = getLevelDimensions(level.level.level);

        return (
            <div
                className={className}
                key={i}
                id={id}
                onClick={() => {
                    props.handleClick(level.level);
                }}
            >
                <span className="level__number">{level.level.name}</span>
                {ballsComponent({ balls: balls, colors: props.colors })}
                {starsComponent({ stars: level.score })}
                <span className="level__grid">{dim}</span>
                {/* //         &nbsp; {dim} [{props.level.level.opti}] &nbsp; */}
            </div>
        );
    });

    var emptyLevel = props.unlocked > 0 && (
        <div className={"level__link level__link__unlocked"}>
            <span className="level__number">{props.unlocked} to unlock</span>
            {ballsComponent({ colors: ["gray", "gray", "gray"], balls: 3 })}
            {starsComponent({ stars: 0 })}
            <span className="level__grid">???</span>
            {/* //         &nbsp; {dim} [{props.level.level.opti}] &nbsp; */}
        </div>
    );

    return (
        <div>
            {levels}
            {emptyLevel}
        </div>
    );
}

function getLevelDimensions(level) {
    var rows = level.split(";");
    var cols = rows[0].split(" ");

    //return string rows x cols
    return `${cols.length - 2}x${rows.length - 2}`;
}

function ballsComponent(props) {
    var colors = (props.colors ?? ["green", "orange", "blue"]).slice(
        0,
        props.balls
    );
    return (
        <div className="level__points">
            {colors.map((color, i) => {
                return (
                    <MirronBallComponent
                        key={i}
                        color={color}
                    ></MirronBallComponent>
                );
            })}
        </div>
    );
}

function starsComponent(props) {
    return (
        <div className="level__stars">
            <SvgStar score={props.stars} />
        </div>
    );
}

export { GameNavigation };
