import { SvgStar } from "../utils/canvas_util";
import { BackButton } from "../utils/svg_util";

export function getStatistics() {
    var score = [0, 0];
    const key = "totalScore_Main Campaign";

    if (key.startsWith("totalScore_")) {
        var result = localStorage.getItem(key);
        if (result) score = JSON.parse(result);
    }

    var ranksa = [
        [0, "Starter"],
        [10, "Kubobling"],
        [100, "Junior Kubobler"],
        [250, "Kubobler"],
        [500, "Senior Kubobler"],
        [750, "Kuboble Expert"],
        [1000, "Kuboble Master"],
        [1400, "Kuboble Grand Master"],
        [1430, "Kuboble Supreme Master"],
    ];

    var i = ranksa.length - 1;

    while (i > 0 && score[0] < ranksa[i][0]) {
        i--;
    }
    var rank = ranksa[i][1];

    return {
        score: score[0],
        maxScore: 1430,
        ranksTotal: ranksa.length - 1,
        rankName: rank,
        rankIndex: i,
        nextRankScore: i + 1 > ranksa.length - 1 ? "N/A" : ranksa[i + 1][0],
    };
}
