import { getAllLevelsComplete, setLevelComplete } from "./game_score_tracking";

function getRequestUrl() {
    // if localhost return localhost:5000. otherwise return the current url
    if (window.location.hostname === "localhost") {
        return "http://localhost:5000/";
    }
    return "kuboble_results.php";
}
const result_url = getRequestUrl();

async function getResultsFromServer(kuboble_session, kuboble_email) {
    return await fetch(result_url, {
        method: "POST",
        headers: {
            // Accept: "application/json",
            // "Content-Type": "application/json",
        },
        body: JSON.stringify({
            kuboble_session: kuboble_session,
            kuboble_email: kuboble_email,
            kuboble_action: "get_results",
        }),
    }).then((response) => response.json());
}

// results is an array of objects with code and moves
// for example
/*   kuboble_results: [
            {
                code: "17a96e3cd1e5c029809e5ada508deeb3f913b6008c92ba44cb28ae54081525a5",
                moves: 101,
            },*/
export function setResultsOnTheServer(
    kuboble_session,
    kuboble_email,
    kuboble_results
) {
    fetch(result_url, {
        method: "POST",
        headers: {
            // Accept: "application/json",
            // "Content-Type": "application/json",
        },
        body: JSON.stringify({
            kuboble_session: kuboble_session,
            kuboble_email: kuboble_email,
            kuboble_action: "set_results",
            kuboble_results: kuboble_results,
        }),
    }).then((response) => response.text());
}

function li_to_di(li) {
    var di = {};
    for (const idx in li) {
        const item = li[idx];
        di[item["code"]] = item["moves"];
    }
    return di;
}

export function synchronizeResults(
    kuboble_session,
    kuboble_email,
    messageCallback
) {
    var local_results = getAllLevelsComplete();
    var local_results_dict = li_to_di(local_results);

    var results = getResultsFromServer(kuboble_session, kuboble_email);
    results.then((results) => {
        const level_results = results["level_results"];

        const server_result_di = li_to_di(level_results);

        var levels_to_export = 0;
        var levels_to_import = 0;

        for (const idx in level_results) {
            var result = level_results[idx];
            var level_code = result["code"];
            var level_moves = result["moves"];
            var local_result = local_results_dict[level_code];
            if (!local_result || local_result > level_moves) {
                levels_to_import++;
                setLevelComplete(level_code, level_moves);
            }
        }

        var all_to_export = [];
        for (const idx in local_results) {
            var result = local_results[idx];
            var level_code = result["code"];
            var level_moves = result["moves"];
            var server_result = server_result_di[level_code];
            if (!server_result || server_result > level_moves) {
                levels_to_export++;
                all_to_export.push(result);
            }
        }
        if (all_to_export.length > 0) {
            setResultsOnTheServer(
                kuboble_session,
                kuboble_email,
                all_to_export
            );
        }
        var msg =
            "Syncronized results with other device. " +
            levels_to_import +
            " levels imported, " +
            levels_to_export +
            " levels exported.";
        console.log(msg);
        if (messageCallback) {
            messageCallback(msg);
        }
    });
}

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
}

export function isStringUuidv4(inputString) {
    const pattern = /^[a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}$/i;
    return pattern.test(inputString);
}
