import { useEffect, useState } from "react";
import { Blog, BlogEntry } from "../info/blog";
import { getStatistics } from "../info/info";
import { SvgStar } from "../utils/canvas_util";
import { uuidv4 } from "../utils/score_sync_util";
import {
    BackButton,
    MirronBallComponent,
    VerticalBoardItem,
} from "../utils/svg_util";

export function getSettingValue(key, defaultValue) {
    const settingsKey = "settings_" + key;
    let result = localStorage.getItem(settingsKey);
    if (result) {
        try {
            return JSON.parse(result);
        } catch (e) {
            return defaultValue;
        }
    }
    return defaultValue;
}

export function setSettingValue(key, value) {
    const settingsKey = "settings_" + key;
    localStorage.setItem(settingsKey, JSON.stringify(value));
}

export function SettingsComponent(props) {
    var [sounds, setSounds] = useState(getSettingValue("sounds", 0));
    var [colorScheme, setColorScheme] = useState(
        getSettingValue("colorScheme", "")
    );
    var [highglightSelected, setHighglightSelected] = useState(
        getSettingValue("highglightSelected", 0)
    );
    var [colorScheme, setColorScheme] = useState(
        getSettingValue("orientation", "auto")
    );

    var [synchronizeResults, setSynchronizeResults] = useState(
        getSettingValue("synchronizeResults", 0)
    );

    var score = getStatistics();

    return (
        <main className="main">
            <header className="header">
                <h1 className="header__logo">Settings</h1>
            </header>

            <BackButton onClick={props.backButtonOnClick}> </BackButton>
            <OnOffFilter
                title="Sound effects"
                items={[
                    ["OFF", 0],
                    ["ON", 1],
                ]}
                currentValue={sounds}
                setValue={(value) => {
                    setSounds(value);
                    setSettingValue("sounds", value);
                }}
            ></OnOffFilter>
            <OnOffFilter
                title="Colors"
                items={[
                    [
                        <div>
                            <MirronBallComponent color={"green"} />
                            <MirronBallComponent color={"orange"} />
                        </div>,
                        "",
                    ],
                    [
                        <div>
                            <MirronBallComponent color={"white"} />
                            <MirronBallComponent color={"black"} />
                        </div>,
                        "HC",
                    ],
                ]}
                currentValue={colorScheme}
                setValue={(value) => {
                    setColorScheme(value);
                    setSettingValue("colorScheme", value);
                }}
            ></OnOffFilter>

            <OnOffFilter
                title="Orientation"
                items={[
                    [<div>AUTO</div>, "auto"],
                    [
                        <div>
                            <VerticalBoardItem x={20} y={30} />
                        </div>,
                        "vertical",
                    ],
                    [
                        <div>
                            <VerticalBoardItem x={30} y={20} />
                        </div>,
                        "horizontal",
                    ],
                ]}
                currentValue={colorScheme}
                setValue={(value) => {
                    setColorScheme(value);
                    setSettingValue("orientation", value);
                }}
            ></OnOffFilter>

            <OnOffFilter
                title="Highlight Selected Stone"
                items={[
                    ["OFF", 0],
                    ["ON", 1],
                ]}
                currentValue={highglightSelected}
                setValue={(value) => {
                    setHighglightSelected(value);
                    setSettingValue("highglightSelected", value);
                }}
            ></OnOffFilter>

            <OnOffFilter
                title="Synchronize progress across devices"
                items={[
                    ["OFF", 0],
                    ["ON", 1],
                ]}
                currentValue={synchronizeResults}
                setValue={(value) => {
                    setSynchronizeResults(value);
                    setSettingValue("synchronizeResults", value);
                }}
            ></OnOffFilter>
            {synchronizeResults == 1 ? (
                <SynchronizeComponent setMessage={props.setMessage} />
            ) : (
                " "
            )}
            {score.score > 0 ? (
                <ScoreComponent score={score}></ScoreComponent>
            ) : (
                <HelpComponent />
            )}
            <Blog></Blog>
        </main>
    );
}

function HelpComponent(props) {
    return (
        <BlogEntry title="Welcome to Kuboble!">
            <p>Try to find a way home for all the balls!</p>
            <p> Have fun!</p>
        </BlogEntry>
    );
}

function RowResult(props) {
    return (
        <div className="filter__stars stars">
            <span className="stars__title">{props.title}</span>
            <div className="stars__body">
                <div className="stars__item info__item">{props.text}</div>
            </div>
        </div>
    );
}

function ScoreComponent(props) {
    var score = props.score;
    console.log(score.rankName);
    var ptsText =
        score.score +
        " Points (" +
        ((score.score * 100) / score.maxScore).toFixed(2) +
        "%)";
    return (
        <div className="info__stats">
            <h1>Your progress</h1>
            <RowResult title="Points" text={ptsText} />
            <div className="filter__stars center">
                <SvgStar
                    height="2em"
                    total={score.ranksTotal}
                    score={score.rankIndex}
                />
            </div>
            <RowResult title="Your rank" text={score.rankName} />
            <RowResult
                title="Next rank at"
                text={score.nextRankScore + " points"}
            />

            <div className="filter__levels"> &nbsp; </div>
        </div>
    );
}

function SynchronizeComponent(props) {
    var [synchronizeSessionKey, setSynchronizeSessionKey] = useState(
        getSettingValue("synchronizeSessionKey", null)
    );

    useEffect(() => {
        if (synchronizeSessionKey == null) {
            var skey = uuidv4();
            setSynchronizeSessionKey(skey);
            setSettingValue("synchronizeSessionKey", skey);
        }
    }, []);

    const handleClickShare = (e) => {
        e.preventDefault();
        var url =
            window.location.origin +
            window.location.pathname +
            "?sync=" +
            synchronizeSessionKey;
        navigator.clipboard.writeText(url);
        props.setMessage("Link copied!");
    };
    return (
        <div className="level__filter filter">
            <form>
                <RowResult title="Your key" text={synchronizeSessionKey} />

                <div className="filter__stars stars ">
                    <button
                        className="settings_extra_text"
                        onClick={handleClickShare}
                    >
                        Share this link to sync with another device.
                    </button>
                </div>
            </form>
        </div>
    );
}

function OnOffFilter(props) {
    var labels = props.items;
    return (
        <div className="level__filter filter">
            <div className="filter__stars stars">
                <span className="stars__title">{props.title}</span>
                <div className="stars__body">
                    {labels.map((label, i) => {
                        var active = props.currentValue === label[1];
                        var value = label[1];
                        return (
                            <div
                                key={i}
                                className={`sort__item ${
                                    active ? "_active" : ""
                                }`}
                                onClick={() => {
                                    props.setValue(value);
                                }}
                            >
                                {label[0]}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
