import { SvgStar } from "../utils/svg_util";

export function LevelFilter(props) {
    var labels = [
        ["ALL", [0, 1, 2, 3, 4, 5]],
        [<SvgStar total={2} score={0} />, [0]],
        [<SvgStar total={2} score={1} />, [1, 2, 3, 4]],
        [<SvgStar total={2} score={2} />, [5]],
    ];
    const val_equal = (a, b) => {
        for (var i = 0; i <= 5; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    };

    return (
        <div className="level__filter filter">
            <div className="filter__stars stars">
                <span className="stars__title">Filter:</span>
                <div className="stars__body">
                    {labels.map((label, i) => {
                        const new_show = {
                            0: false,
                            1: false,
                            2: false,
                            3: false,
                            4: false,
                            5: false,
                        };
                        label[1].forEach((val) => {
                            new_show[val] = true;
                        });

                        var active = val_equal(
                            props.showLevelsWithScore,
                            new_show
                        );
                        return (
                            <div
                                key={i}
                                className={`sort__item ${
                                    active ? "_active" : ""
                                }`}
                                onClick={() => {
                                    props.setShowLevelsWithScore(new_show);
                                }}
                            >
                                {label[0]}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
