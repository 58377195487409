import {
    drawStars,
    getButtonRectangle,
    rectangleInTheMiddle,
    X_ALIGN,
    Y_ALIGN,
} from "../../utils/canvas_util";
import { BOARD_TILES, MOUSE_OVER_STATES } from "../../utils/enums";
import { getValueOrEvaluate } from "../../utils/eval_util";
import { getLevelComplete } from "../../utils/game_score_tracking";
import { getSettingValue } from "../settings";

const SCREENS = { GameScreen: 1, WinScreen: 2, InfoScreen: 3 };

function dist(Ax, Ay, Bx, By) {
    return Math.sqrt((Ax - Bx) * (Ax - Bx) + (Ay - By) * (Ay - By));
}

class GameState {
    constructor(props) {
        this.props = props;

        // the grid is a decription of board - where are walls, and targets
        this.grid = [];

        // all movable game objects
        this.balls = [];
        this.gridWidth = 3;
        this.gridHeight = 3;

        // list of moves that can be undone
        this.undo_stack = [];

        // drawing constants - how big is the board, how big is the ball etc.
        this.cellSize = 1;
        this.offsetX = 0;
        this.offsetY = 0;
        this.ballRadius = 20;
        this.ballShadowSize = 3;

        //currently dragged ball
        this.dragged = null;
        this.animationQueue = [];
        this.currentAnimation = null;

        this.currentScreen = SCREENS.GameScreen;

        this.needsRender = true;

        this.highlightSelectedPiece =
            getSettingValue("highglightSelected", 0) === 1;

        var colorScheme = getSettingValue("colorScheme", "");
        if (colorScheme !== "HC") {
            colorScheme = "";
        }
        this.colorScheme = colorScheme;

        this.orientation = getSettingValue("orientation", "auto");

        this.currentlySelectedBall = null;

        var playAudio = getSettingValue("sounds", 0);
        if (playAudio === 1) {
            this.audio = new Audio("./sound/knock16.wav");
            this.audio.preload = "auto";
            this.audio.load();
        } else {
            this.audio = null;
        }

        this.images = new DrawingUtil();
        //position menu button at the top of the screen, opposite of next level button
        let menuButton = {
            screens: [
                SCREENS.GameScreen,
                SCREENS.WinScreen,
                SCREENS.InfoScreen,
            ],
            label: "Menu", // text on the button
            recalculateSize: (canvas) =>
                getButtonRectangle(canvas, X_ALIGN.LEFT, Y_ALIGN.TOP),
            action: () => this.props.onMenu(), // what to do when the button is clicked
        };

        let levelOptionsButton = {
            screens: [SCREENS.InfoScreen, SCREENS.GameScreen],
            label: () => this.level.name, // text on the button
            recalculateSize: (canvas) =>
                getButtonRectangle(canvas, X_ALIGN.RIGHT, Y_ALIGN.TOP),
            action: () => {
                if (this.currentScreen === SCREENS.InfoScreen) {
                    this.currentScreen = SCREENS.GameScreen;
                    this.dragged = null;
                } else {
                    this.currentScreen = SCREENS.InfoScreen;
                }
                this.needsRender = true;
            }, // what to do when the button is clicked
        };

        let undoButton = {
            screens: [SCREENS.GameScreen],
            label: () => `Undo (${this.moves()})`,
            recalculateSize: (canvas) =>
                getButtonRectangle(canvas, X_ALIGN.LEFT, Y_ALIGN.BOTTOM),
            action: () => this.undo(),
        };
        let tryAgainButton = {
            screens: [SCREENS.WinScreen, SCREENS.InfoScreen],
            label: "Try again",
            recalculateSize: (canvas) =>
                getButtonRectangle(canvas, X_ALIGN.LEFT, Y_ALIGN.BOTTOM),
            action: () => {
                this.setUpLevel(this.level.level);
            },
        };

        const params = new URLSearchParams(window.location.search);
        var nextLevelButton;
        var levelParam = params.get("level");
        if (levelParam) {
            nextLevelButton = {
                screens: [SCREENS.WinScreen, SCREENS.InfoScreen],
                label: "I love this level!",
                fillStyle: "rgba(255, 255, 255, 0.35)",
                recalculateSize: (canvas) =>
                    getButtonRectangle(canvas, X_ALIGN.RIGHT, Y_ALIGN.BOTTOM),
                action: () => {
                    var url = `https://kuboble.com/kuboble_level_completed/?kuboble_feedback=${this.level.hash}_${this.level.name}`;
                    fetch(url, {
                        method: "POST",
                        headers: {
                            Accept: "application/text",
                            "Content-Type": "application/text",
                        },
                    })
                        .then((response) => response.text())
                        .then((response) => console.log(response));
                },
            };
        } else {
            nextLevelButton = {
                screens: [SCREENS.WinScreen, SCREENS.InfoScreen],
                label: "Next level",
                fillStyle: "rgba(255, 255, 255, 0.35)",
                recalculateSize: (canvas) =>
                    getButtonRectangle(canvas, X_ALIGN.RIGHT, Y_ALIGN.BOTTOM),
                action: () => this.props.onNextLevel(this.level),
            };
        }
        // a button is a pair of a label, visilbility and a rescale function
        this.buttons = [
            undoButton,
            menuButton,
            levelOptionsButton,
            tryAgainButton,
            nextLevelButton,
        ];

        for (let i = 0; i < this.buttons.length; i++) {
            //initial create property .state on all buttons
            this.buttons[i].state = MOUSE_OVER_STATES.NOTHING;
        }

        let state = this;
        let goalLabel = {
            getText: function () {
                return `Goal: ${state.level.opti}`;
            },
            recalculateSize: (canvas) =>
                getButtonRectangle(canvas, X_ALIGN.RIGHT, Y_ALIGN.BOTTOM),
        };

        this.labels = [goalLabel];
    }

    // is empty if grid >= -1 and no ball is in this grid location
    isEmpty(gridY, gridX) {
        return (
            this.grid[gridY][gridX] >= -1 &&
            this.balls.filter((b) => b.gridX === gridX && b.gridY === gridY)
                .length === 0
        );
    }

    moves() {
        return this.undo_stack.length;
    }

    ballLocation(gridX, gridY) {
        return {
            x: this.cellSize * gridX + this.cellSize / 2,
            y: this.cellSize * gridY + this.cellSize / 2,
        };
    }

    //get grid location for canvas x and y
    gridLocation(x, y) {
        return {
            x: Math.floor(x / this.cellSize),
            y: Math.floor(y / this.cellSize),
        };
    }

    inAnimation() {
        return this.animationQueue.length > 0 || this.currentAnimation;
    }

    //parse level and return array of rows and size of the grid
    parseLevel(lev) {
        const rows = lev
            .trim()
            .split(";")
            .filter((x) => x.trim() !== "");
        var max_row = 0;
        var result = [];
        for (let y = 0; y < rows.length; y++) {
            const row = rows[y]
                .trim()
                .split(" ")
                .filter((x) => x.trim() !== "");
            max_row = Math.max(max_row, row.length);
            result.push(row);
        }
        return [result, rows.length, max_row];
    }

    setUpLevel(lev) {
        var rows;
        [rows, this.gridHeight, this.gridWidth] = this.parseLevel(lev);
        this.grid = [];
        this.balls = [];
        this.currentScreen = SCREENS.GameScreen;
        this.dragged = null;
        this.undo_stack = [];
        for (let y = 0; y < this.gridHeight; y++) {
            this.grid.push([]);
            for (let x = 0; x < this.gridWidth; x++) {
                this.grid[y].push(BOARD_TILES.INVISIBLE_WALL);
            }
        }

        for (let rY = 0; rY < rows.length; rY++) {
            const row = rows[rY];
            for (let rX = 0; rX < row.length; rX++) {
                const x = row[rX];
                if (x === ".") {
                    this.grid[rY][rX] = BOARD_TILES.FREE;
                    // empty field - do nothing
                } else if (x === "X") {
                    // wall
                    this.grid[rY][rX] = BOARD_TILES.WALL;
                } else if (x === "Y") {
                    // wall but out of bounds so invisible
                    this.grid[rY][rX] = BOARD_TILES.INVISIBLE_WALL;
                } else {
                    this.grid[rY][rX] = BOARD_TILES.FREE;
                    for (let i = 0; i < x.length; i++) {
                        let ch = x[i];
                        let ch_index = x.toLowerCase().charCodeAt(i) - 97;
                        if (ch === ch.toUpperCase()) {
                            // starting location
                            var pt = this.ballLocation(rX, rY);

                            this.balls.push({
                                gridX: rX,
                                gridY: rY,
                                x: pt.x,
                                y: pt.y,
                                letter: ch_index,
                                index: this.balls.length,
                            });
                        } else {
                            this.grid[rY][rX] = ch_index;
                            //target location
                        }
                    }
                }
            }
        }
        if (
            this.orientation == "vertical" ||
            (this.orientation == "auto" &&
                this.canvas.height > this.canvas.width * 1.3)
        ) {
            this.transposeGridAndBalls();
        }
        this.recalcSizes(this.canvas);
        this.currentlySelectedBall = this.balls[0];
        this.needsRender = true;
    }

    isIn(x, y) {
        return x >= 0 && y >= 0 && x < this.gridWidth && y < this.gridHeight;
    }

    //rescale objects to fit canvas size
    recalcSizes(canvas) {
        this.cellSize = Math.min(
            canvas.width / (this.gridWidth - 0.5),
            canvas.height / (3 + this.gridHeight)
        );
        this.ballRadius = (this.cellSize - 10) / 2;
        this.offsetX = canvas.width / 2 - (this.cellSize * this.gridWidth) / 2;
        this.offsetY =
            canvas.height / 2 - (this.cellSize * this.gridHeight) / 2;

        for (var i = 0; i < this.buttons.length; i++) {
            var button = this.buttons[i];
            button.rect = button.recalculateSize(canvas);
        }
        for (var li = 0; li < this.labels.length; li++) {
            var label = this.labels[li];
            label.rect = label.recalculateSize(canvas);
        }

        for (var pi in this.balls) {
            const p = this.balls[pi];
            p.x = this.ballLocation(p.gridX, p.gridY).x;
            p.y = this.ballLocation(p.gridX, p.gridY).y;
        }
        this.needsRender = true;
    }

    init(level, canvas) {
        this.canvas = canvas;
        this.level = level;
        this.setUpLevel(level.level);
    }

    transposeGridAndBalls() {
        var newGrid = [];
        var newBalls = [];

        for (var i = 0; i < this.grid[0].length; i++) {
            newGrid.push([]);
            for (var j = this.grid.length - 1; j >= 0; j--) {
                // old y,x   - j,i. -  new = i, this.grid.length - 1 - j
                newGrid[i].push(this.grid[j][i]);
            }
        }

        //swap gridwidth and gridheight
        var temp = this.gridWidth;
        this.gridWidth = this.gridHeight;
        this.gridHeight = temp;

        for (var bi = 0; bi < this.balls.length; bi++) {
            var ball = this.balls[bi];
            var newx = this.grid.length - 1 - ball.gridY;
            var newy = ball.gridX;
            let targetPosition = this.ballLocation(newx, newy);
            newBalls.push({
                gridX: newx,
                gridY: newy,
                x: targetPosition.x,
                y: targetPosition.y,
                letter: ball.letter,
                index: ball.index,
            });
        }
        this.balls = newBalls;
        this.grid = newGrid;
        this.needsRender = true;
    }

    animation_progress(animation, frame) {
        let x = frame / animation.duration;
        if (frame > animation.duration) x = 1;
        let f = (from, to) => {
            return from + ((to - from) * (x * x + x)) / 2;
        };
        let px = f(animation.pA.x, animation.pB.x, x);
        let py = f(animation.pA.y, animation.pB.y, x);
        return [px, py];
    }

    update() {
        //Stopping update if we aren't in game screen
        if (this.currentScreen !== SCREENS.GameScreen) return;
        const pnow = performance.now();

        if (!(this.dragged && this.dragged.ball) && !this.inAnimation()) {
            //early return if we are not dragging a ball and there is no animation
            return;
        }

        this.needsRender = true;

        if (this.currentAnimation === null && this.animationQueue.length > 0) {
            this.currentAnimation = [this.animationQueue.shift(), pnow]; // an animation, starting time, duration, framecount
        }

        if (this.currentAnimation) {
            let [toMove, start_timestamp] = this.currentAnimation;
            let [px, py] = this.animation_progress(
                toMove,
                pnow - start_timestamp
            );
            toMove.ball.x = px;
            toMove.ball.y = py;
            if (start_timestamp + toMove.duration < pnow) {
                this.currentAnimation = null;
                if (toMove.playSound) {
                    this.playKnockSound();
                }
            }
        }
        if (
            this.animationQueue.length === 0 &&
            this.currentAnimation === null &&
            this.balls.every((p) => {
                return this.grid[p.gridY][p.gridX] === p.letter;
            })
        ) {
            this.props.onLevelComplete(this.level, this.moves());
            this.finalMoves = this.moves();
            this.currentScreen = SCREENS.WinScreen;
            this.needsRender = true;
        }
    }

    drawBackground(canvas, canvasContext) {
        canvasContext.drawImage(
            this.images.imageBackground,
            0,
            0,
            Math.max(canvas.width, this.images.imageBackground.width),
            canvas.height
        );
    }

    drawText(canvasContext, text, centerx, centery, fontSize) {
        canvasContext.fillStyle = "white";
        canvasContext.textAlign = "centre";
        canvasContext.textBaseline = "middle";
        canvasContext.font = `${this.ballRadius * fontSize}px Arial`;
        canvasContext.fillText(
            text,
            centerx - canvasContext.measureText(text).width / 2,
            centery
        );
    }

    drawLevelInfo(canvas, canvasContext, level, header, moves) {
        var offsetY = canvas.height * 0.15;
        offsetY += canvas.height / 12;
        canvasContext.globalAlpha = 0.9;
        this.drawText(
            canvasContext,
            "Level: " + level.name,
            canvas.width / 2,
            offsetY,
            0.6
        );

        offsetY += canvas.height / 12;

        this.drawText(canvasContext, header, canvas.width / 2, offsetY, 1);

        offsetY += canvas.height / 8;
        this.drawText(
            canvasContext,
            `Score: ${moves}`,
            canvas.width / 2,
            offsetY,
            0.75
        );

        offsetY += canvas.height / 10;
        const opti = this.level.opti;
        this.drawText(
            canvasContext,
            `Optimal: ${opti}`,
            canvas.width / 2,
            offsetY,
            0.75
        );

        var score = 0;
        var five_stars = 5;
        if (moves) score = Math.max(1, opti + five_stars - moves);
        offsetY += canvas.height / 8;
        const starsize = Math.min(canvas.height / 12, canvas.width / 9);
        drawStars(
            canvasContext,
            canvas.width / 2 - 2 * starsize,
            offsetY,
            starsize,
            score
        );
    }
    drawWin(canvas, canvasContext) {
        this.drawLevelInfo(
            canvas,
            canvasContext,
            this.level,
            "Congratulations!",
            this.finalMoves
        );
    }

    drawInfo(canvas, canvasContext) {
        var labelHeader;
        var levelComplete = getLevelComplete(this.level);

        if (levelComplete.moves) {
            labelHeader = "Level already completed";
        } else {
            labelHeader = "Level not completed";
        }
        this.drawLevelInfo(
            canvas,
            canvasContext,
            this.level,
            labelHeader,
            levelComplete.moves
        );
    }

    drawButtons(screen, canvas, context) {
        for (var i = 0; i < this.buttons.length; i++) {
            const button = this.buttons[i];
            if (button.screens.includes(screen)) {
                context.fillStyle = "rgba(255, 255, 255, 0.35)";
                if (button.state === MOUSE_OVER_STATES.MOUSE_OVER) {
                    context.fillStyle = "rgba(255, 255, 0, 0.5)";
                } else if (button.state === MOUSE_OVER_STATES.MOUSE_DOWN) {
                    context.fillStyle = "rgba(255, 000, 0, 0.2)";
                }
                const rect = button.rect;
                context.fillRect(rect.x, rect.y, rect.width, rect.height);

                context.fillStyle = "white";
                context.textBaseline = "middle";
                context.font = `${this.ballRadius * 0.75}px Arial`;

                var text = getValueOrEvaluate(button.label);

                context.fillText(
                    text,
                    rect.x +
                        rect.width / 2 -
                        context.measureText(text).width / 2,
                    rect.y + rect.height / 2
                );
            }
        }
    }

    drawGameInTheBackground(canvas, context) {
        this.images.drawMap(this, context);
        this.drawBalls(context);

        context.globalAlpha = 0.9;
        var rect = rectangleInTheMiddle(canvas);
        //fill rectangle with black 50% transparent
        context.fillStyle = "rgba(0,0,0,0.7)";
        context.fillRect(rect.x, rect.y, rect.width, rect.height);
    }

    draw(canvas) {
        let context = canvas.getContext("2d");

        this.drawBackground(canvas, context);

        if (this.currentScreen === SCREENS.WinScreen) {
            //Drawing the text behind the map
            this.drawGameInTheBackground(canvas, context);
            this.drawWin(canvas, context);

            //Never drawing anything except the win text if we got the level
        }
        if (this.currentScreen === SCREENS.InfoScreen) {
            // this.drawButtons(SCREENS.InfoScreen, canvas, context);
            this.drawGameInTheBackground(canvas, context);
            this.drawInfo(canvas, context);
            //draw info about level progress probably
        }
        if (this.currentScreen === SCREENS.GameScreen) {
            this.drawGame(canvas, context);
        }
        this.drawButtons(this.currentScreen, canvas, context);
        if (this.images.imagesAreLoaded()) {
            this.needsRender = false;
        }
    }

    drawBalls(context) {
        //drawing balls
        for (var pi in this.balls) {
            const p = this.balls[pi];

            var ballShadowSize = this.ballShadowSize;
            var cellSize = this.cellSize;
            if (this.dragged && this.dragged.ball === p) {
                ballShadowSize *= 1.1;
                cellSize *= 1.1;
            }

            {
                // draw shadow
                context.fillStyle = "black";
                context.globalAlpha = 0.45;
                context.beginPath();

                context.arc(
                    this.offsetX + p.x - ballShadowSize,
                    this.offsetY + p.y - ballShadowSize,
                    cellSize / 2 - ballShadowSize / 2,
                    0,
                    2 * Math.PI
                );
                context.closePath();
                context.fill();
            }

            context.globalAlpha = 1;
            var margin = cellSize / 17;
            context.drawImage(
                this.images.ballImages[p.letter],
                this.offsetX + p.x - cellSize / 2 + margin,
                this.offsetY + p.y - cellSize / 2 + margin,
                cellSize - 2 * margin,
                cellSize - 2 * margin
            );

            if (
                this.highlightSelectedPiece &&
                this.currentlySelectedBall === p
            ) {
                // draw selection
                var highglighSize = cellSize * 0.6;

                context.fillStyle = "white";
                if (this.colorScheme === "HC" && p.index == 1) {
                    context.fillStyle = "black";
                }

                context.globalAlpha = 0.5;
                context.beginPath();

                // ballShadowSize *= 2;
                // cellSize *= 2;
                // console.log(cellSize / 2, ballShadowSize / 2, cellSize / 2 - ballShadowSize / 2)
                context.arc(
                    this.offsetX + p.x,
                    this.offsetY + p.y,
                    highglighSize / 2,
                    0,
                    2 * Math.PI
                );
                context.closePath();
                context.fill();
            }
        }
    }

    drawGame(canvas, context) {
        this.images.drawMap(this, context);

        //The map is drawn on seperete canvas
        //If we add 3d, the functions will be slow, so we won't draw it every frame
        //But for now it's ok

        this.drawBalls(context);
        //Buttons and labels
        context.globalAlpha = this.fadeOut;
        context.font = `${this.ballRadius * 0.75}px Arial`;
        context.fillStyle = "white";
        context.textAlign = "left";
        context.textBaseline = "middle";
        for (var i = 0; i < this.labels.length; i++) {
            var label = this.labels[i];
            var rect = label.rect;
            let text = label.getText();
            context.fillText(
                text,
                rect.x + rect.width / 2 - context.measureText(text).width / 2,
                rect.y + rect.height / 2
            );
        }
        context.globalAlpha = 1;
    }

    //////////////////////////////////////////////
    ///-----------------MOVING-----------------///
    //////////////////////////////////////////////

    directiond(dx, dy) {
        let angleDeg = (Math.atan2(dy, dx) * 180) / Math.PI;
        if (angleDeg >= -45 && angleDeg <= 45) return { x: 1, y: 0 };
        if (angleDeg >= 45 && angleDeg <= 135) return { x: 0, y: 1 };
        if (angleDeg >= -135 && angleDeg <= -45) return { x: 0, y: -1 };
        return { x: -1, y: 0 };
    }

    direction(p1, p2) {
        return this.directiond(p2.x - p1.x, p2.y - p1.y);
    }

    indexInGrid(gridX, gridY) {
        return gridY in this.grid && gridX in this.grid[gridY];
    }

    animateBallBackToGrid(ball) {
        this.animationQueue.push({
            ball: ball,
            pA: { x: ball.x, y: ball.y },
            pB: this.ballLocation(ball.gridX, ball.gridY),
            duration: 200,
        });
    }

    animateUndoMove(ball, fromGridX, fromGridY) {
        this.animationQueue.push({
            ball: ball,
            pA: this.ballLocation(fromGridX, fromGridY),
            pB: this.ballLocation(ball.gridX, ball.gridY),
            duration: 100,
            playSound: true,
        });
    }

    findBallComingFromDirection(tile, delta) {
        var targetX = tile.x;
        var targetY = tile.y;
        while (this.indexInGrid(targetX, targetY)) {
            targetX -= delta.x;
            targetY -= delta.y;
            for (var i = 0; i < this.balls.length; i++) {
                var ball = this.balls[i];
                if (ball.gridX === targetX && ball.gridY === targetY) {
                    return ball;
                }
            }
        }
    }

    playKnockSound() {
        if (this.audio) {
            var click = this.audio.cloneNode();
            click.play();
        }
    }

    FROM_STARTING_LOCATION = 0;
    FROM_CURRENT_LOCATION = 1;

    slidePiece(ball, delta, duration, from_location) {
        var targetX = ball.gridX;
        var targetY = ball.gridY;

        if (!this.isEmpty(targetY + delta.y, targetX + delta.x)) {
            return false; // the ball can't be moved in the specified direction
        }

        while (this.isEmpty(targetY + delta.y, targetX + delta.x)) {
            targetX += delta.x;
            targetY += delta.y;
        }

        let move = {
            ball: ball,
            startGrid: {
                x: ball.gridX,
                y: ball.gridY,
            },
            endGrid: {
                x: targetX,
                y: targetY,
            },
        };

        // if a user has dragged a ball we want to animate it from current posision
        // but if there is some animation in progress and user e.g. clicked fast with keyboard we
        // want to animate it from the new starting location from after the animation has completed
        var startingLocation = { x: ball.x, y: ball.y };
        if (from_location === this.FROM_STARTING_LOCATION) {
            startingLocation = this.ballLocation(ball.gridX, ball.gridY);
        }

        ball.gridX = targetX;
        ball.gridY = targetY;

        if (this.currentAnimation != null) {
            duration *= 0.75;
        }
        if (this.animationQueue.length > 0) {
            duration *= 0.75;
        }

        let targetPosition = this.ballLocation(targetX, targetY);
        this.animationQueue.push({
            ball: ball,
            pA: startingLocation,
            pB: targetPosition,
            duration: duration,
            playSound: true,
        });
        this.undo_stack.push(move);
        return true;
    }

    undo() {
        if (this.currentScreen !== SCREENS.GameScreen) return;
        if (this.undo_stack.length === 0) return;
        if (this.inAnimation()) return;
        let last_move = this.undo_stack.pop();
        let ball = last_move.ball;
        ball.gridX = last_move.startGrid.x;
        ball.gridY = last_move.startGrid.y;
        this.animateUndoMove(ball, last_move.endGrid.x, last_move.endGrid.y);
    }

    dragBallFromCurrentLocation(ball, mouseX, mouseY) {
        this.currentlySelectedBall = ball;
        this.dragged = {
            ball: ball,
            originalX: ball.x,
            originalY: ball.y,
            mX: mouseX,
            mY: mouseY,
        };
    }

    ballUnderMouse(mouseX, mouseY) {
        return this.balls.filter(
            (p) =>
                dist(p.x, p.y, mouseX - this.offsetX, mouseY - this.offsetY) <
                this.ballRadius
        )[0];
    }

    mouseOverRect(rect, mouseX, mouseY) {
        return (
            mouseX > rect.x &&
            mouseX < rect.x + rect.width &&
            mouseY > rect.y &&
            mouseY < rect.y + rect.height
        );
    }

    /* clicking state is 
         0 for nothing
         1 mouse is over
         2 mouse is down
         3 mouse up
         we go to max(current state, old state) if mouse over and set to zero otherwise
    */

    keymove_in_direction(d) {
        this.highlightSelectedPiece = true;
        var index = 0;
        if (this.currentlySelectedBall != null) {
            index = this.currentlySelectedBall.index;
        }
        for (var i = 0; i < this.balls.length; i++) {
            var ball = this.balls[(index + i) % this.balls.length];
            if (this.slidePiece(ball, d, 150, this.FROM_STARTING_LOCATION)) {
                this.currentlySelectedBall = ball;
                return;
            }
        }
    }

    keydown(e) {
        this.needsRender = true;
        if (this.currentScreen === SCREENS.GameScreen) {
            switch (e.key) {
                case "ArrowLeft":
                    this.keymove_in_direction(this.directiond(-1, 0));
                    break;
                case "ArrowRight":
                    this.keymove_in_direction(this.directiond(1, 0));
                    break;
                case "ArrowUp":
                    this.keymove_in_direction(this.directiond(0, -1));
                    break;
                case "ArrowDown":
                    this.keymove_in_direction(this.directiond(0, 1));
                    break;
                case "Escape":
                    this.currentlySelectedBall = null;
                    this.highlightSelectedPiece = false;
                    break;
                case "Backspace":
                    this.undo();
                    break;
                case "Tab":
                    this.highlightSelectedPiece = true;
                    var direction = 1;
                    if (e.shiftKey) {
                        direction = -1;
                    }
                    var index = 0;
                    if (this.currentlySelectedBall != null) {
                        index =
                            (this.currentlySelectedBall.index +
                                direction +
                                this.balls.length) %
                            this.balls.length;
                    }
                    this.currentlySelectedBall = this.balls[index];
                    break;
                default:
                    break;
            }
            e.preventDefault();
        }
    }

    setButtonState(button, state) {
        if (button.state !== state) {
            button.state = state;
            this.needsRender = true;
        }
    }
    updateButtons(mouseX, mouseY, clickState) {
        for (let i = 0; i < this.buttons.length; i++) {
            let button = this.buttons[i];
            if (this.mouseOverRect(button.rect, mouseX, mouseY)) {
                this.setButtonState(button, Math.max(button.state, clickState));
            } else {
                this.setButtonState(button, MOUSE_OVER_STATES.NOTHING);
            }
        }
    }

    checkClickButton(mouseX, mouseY) {
        const screen = this.currentScreen;
        for (let i = 0; i < this.buttons.length; i++) {
            let button = this.buttons[i];
            if (
                button.screens.includes(screen) &&
                this.mouseOverRect(button.rect, mouseX, mouseY) &&
                button.state === MOUSE_OVER_STATES.MOUSE_DOWN
            ) {
                button.action();
            }
            this.setButtonState(button, MOUSE_OVER_STATES.NOTHING);
        }
    }

    mousedown(mouseX, mouseY) {
        this.updateButtons(mouseX, mouseY, MOUSE_OVER_STATES.MOUSE_DOWN);
        this.needsRender = true;

        if (this.currentScreen === SCREENS.GameScreen) {
            let ball = this.ballUnderMouse(mouseX, mouseY);
            if (ball === undefined) {
                this.dragged = {
                    air: true,
                    mx: mouseX - this.offsetX,
                    my: mouseY - this.offsetY,
                };
                return;
            }
            this.dragBallFromCurrentLocation(ball, mouseX, mouseY);
        }
    }

    mouseup(mouseX, mouseY) {
        this.checkClickButton(mouseX, mouseY);
        this.needsRender = true;
        if (this.currentScreen !== SCREENS.GameScreen) return;
        if (this.dragged && this.dragged.ball) {
            // if the item has been dragged then
            // the move should have been applied by the mousemove
            // if the code reaches mouseup it means we just have to release

            //undo temporary animation
            this.animateBallBackToGrid(this.dragged.ball);
        } else if (this.dragged && this.dragged.air) {
            // here we allow moving pieces without dragging them directly
            // by making a swiping motion in some direction
            // and by guessing which piece could that be

            let dmx = mouseX - this.offsetX - this.dragged.mx;
            let dmy = mouseY - this.offsetY - this.dragged.my;

            // if distance covered is more than 1/4 of cellsize
            // find the final tile
            // compute the direction
            // find if there is a piece than can be slided in the requested direction
            // do so if yes so

            if (Math.sqrt(dmx * dmx + dmy * dmy) > this.cellSize / 4) {
                let targetTile = this.gridLocation(
                    mouseX - this.offsetX,
                    mouseY - this.offsetY
                );
                let delta = this.directiond(dmx, dmy);
                if (this.indexInGrid(targetTile.x, targetTile.y)) {
                    let ball = this.findBallComingFromDirection(
                        targetTile,
                        delta
                    );
                    if (ball) {
                        this.slidePiece(
                            ball,
                            delta,
                            200,
                            this.FROM_CURRENT_LOCATION
                        );
                    }
                }
            }
        }
        //release dragged item
        this.dragged = null;
    }

    ballCanMoveInDirection(ball, delta) {
        return this.isEmpty(ball.gridY + delta.y, ball.gridX + delta.x);
    }
    mousemove(mouseX, mouseY) {
        this.updateButtons(mouseX, mouseY, MOUSE_OVER_STATES.MOUSE_OVER);
        if (this.currentScreen !== SCREENS.GameScreen) return;
        if (this.inAnimation()) return;

        if (!this.dragged) return;
        this.needsRender = true;

        const sball = this.ballUnderMouse(mouseX, mouseY);

        if (sball) {
            if (this.dragged.air) {
                this.dragBallFromCurrentLocation(sball, mouseX, mouseY);
            } else if (this.dragged.afterMove && !this.currentAnimation) {
                this.dragBallFromCurrentLocation(sball, mouseX, mouseY);
            } else if (this.dragged.ball && this.dragged.ball !== sball) {
                let dx = mouseX - this.dragged.mX;
                let dy = mouseY - this.dragged.mY;

                if (Math.abs(dx + dy) > this.cellSize / 2) {
                    this.animateBallBackToGrid(this.dragged.ball);
                    this.dragBallFromCurrentLocation(sball, mouseX, mouseY);
                }
            }
        }

        const dball = this.dragged.ball;
        if (dball) {
            let dx = mouseX - this.dragged.mX;
            let dy = mouseY - this.dragged.mY;

            //warning d.x and d.y aren't fully compatible with dx and dy.
            //they are the direction of the ball on the grid, not on the screen

            //direction of only the x component
            const ddx = this.directiond(dx, 0);
            if (!this.ballCanMoveInDirection(dball, ddx)) {
                dx = 0;
            }
            //direction of only the y component
            const ddy = this.directiond(0, dy);
            if (!this.ballCanMoveInDirection(dball, ddy)) {
                dy = 0;
            }

            if (Math.abs(dx) > Math.abs(dy)) {
                dy = 0;
            } else {
                dx = 0;
            }

            const d = this.directiond(dx, dy);
            if (this.isEmpty(dball.gridY + d.y, dball.gridX + d.x)) {
                dball.x = this.dragged.originalX + dx;
                dball.y = this.dragged.originalY + dy;

                if (Math.abs(dx + dy) > this.cellSize / 2) {
                    this.slidePiece(dball, d, 200, this.FROM_CURRENT_LOCATION);
                    this.dragged = { afterMove: true };
                }
            }
        }
    }
}

class DrawingUtil {
    constructor() {
        this.imageTile = new Image();
        this.imageTile.src = "images/tile.png";

        var colorScheme = getSettingValue("colorScheme", "");
        if (colorScheme !== "HC") {
            colorScheme = "";
        }

        this.ballImages = [];
        this.checkpointImages = [];

        for (let i = 0; i < 3; i++) {
            const ballImg = new Image();
            ballImg.src = `images/ball-${colorScheme}${i}.png`;
            this.ballImages.push(ballImg);

            const checkpointImg = new Image();
            checkpointImg.src = `images/tile-${colorScheme}${i}.png`;
            this.checkpointImages.push(checkpointImg);
        }

        this.imageBackground = new Image();
        this.imageBackground.src = "images/background.jpg";
        this.imageWall = new Image();
        this.imageWall.src = "images/wall_dark.jpg";

        this.imageWallRight = new Image();
        this.imageWallRight.src = "images/wall_dark_right.jpg";
    }

    imagesAreLoaded() {
        var sz = [];
        for (let i = 0; i < 3; i++) {
            if (!this.ballImages[i].complete) return false;
            if (!this.checkpointImages[i].complete) return false;
        }
        if (!this.imageTile.complete) return false;
        if (!this.imageBackground.complete) return false;
        if (!this.imageWall.complete) return false;
        if (!this.imageWallRight.complete) return false;
        return true;
    }

    preloadImage(url) {
        var img = new Image();
        img.src = url;
    }

    preloadImages() {
        for (let i = 0; i < 3; i++) {
            this.preloadImage(`images/ball-${i}.png`);
            this.preloadImage(`images/tile-${i}.png`);
        }
        this.preloadImage("images/tile.png");
        this.preloadImage("images/background.jpg");
        this.preloadImage("./images/wall_dark.jpg");
        this.preloadImage("./images/wall_dark_right.jpg");
    }

    drawMap(game_state, canvasContext) {
        for (let y = 0; y < game_state.gridHeight; y++) {
            for (let x = 0; x < game_state.gridWidth; x++) {
                let gridItem = game_state.grid[y][x];
                if (gridItem >= 0) {
                    canvasContext.drawImage(
                        this.checkpointImages[gridItem],
                        game_state.offsetX + x * game_state.cellSize,
                        game_state.offsetY + y * game_state.cellSize,
                        game_state.cellSize,
                        game_state.cellSize
                    );
                }
                if (gridItem === BOARD_TILES.FREE) {
                    canvasContext.drawImage(
                        this.imageTile,
                        game_state.offsetX + x * game_state.cellSize,
                        game_state.offsetY + y * game_state.cellSize,
                        game_state.cellSize,
                        game_state.cellSize
                    );
                }
            }
        }

        var rightWallShift = game_state.cellSize / 17;

        for (let y = 0; y < game_state.gridHeight; y++) {
            for (let x = 0; x < game_state.gridWidth; x++) {
                if (game_state.grid[y][x] === BOARD_TILES.WALL) {
                    if (
                        game_state.isIn(x - 1, y) &&
                        game_state.grid[y][x - 1] >= -1
                    ) {
                        canvasContext.drawImage(
                            this.imageWallRight,
                            game_state.offsetX +
                                x * game_state.cellSize -
                                rightWallShift,
                            game_state.offsetY + y * game_state.cellSize,
                            game_state.cellSize + rightWallShift,
                            game_state.cellSize
                        );
                    } else {
                        canvasContext.drawImage(
                            this.imageWall,
                            game_state.offsetX + x * game_state.cellSize,
                            game_state.offsetY + y * game_state.cellSize,
                            game_state.cellSize,
                            game_state.cellSize
                        );
                    }
                }
            }
        }
    }
}

export { GameState, DrawingUtil };
