import { useEffect, useState } from "react";
import "./App.css";
import { Blog } from "./info/blog";
import { CampaignContents } from "./components/campaign";
import {
    getSettingValue,
    setSettingValue,
    Settings,
    SettingsComponent,
} from "./components/settings";
import { InfoButton, SettingsButton } from "./utils/svg_util";
import InfoPage from "./info/info";
import { isStringUuidv4, synchronizeResults } from "./utils/score_sync_util";

function App() {
    // return div with a background image
    const [page, setPage] = useState("aa");
    const [message, setMessage] = useState("");

    useEffect(() => {
        const should_sync = getSettingValue("synchronizeResults", 0);
        if (should_sync == 1) {
            var key = getSettingValue("synchronizeSessionKey", "");
            var email = getSettingValue("synchronizeSessionEmail", "");
            if (key && isStringUuidv4(key)) {
                console.log(
                    "will sync results because we can and setting is enabled"
                );
                synchronizeResults(key, email);
            }
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params) {
            var sync = params.get("sync");
            if (sync && isStringUuidv4(sync)) {
                console.log("will sync results because requested by the url");
                setSettingValue("synchronizeSessionKey", sync);
                setSettingValue("synchronizeResults", 1);
                var email = getSettingValue("synchronizeSessionEmail", "");
                synchronizeResults(sync, email, setMessage);
            }
        }
    }, []);

    const closeButtonOnClick = () => {
        setMessage("");
    };

    return (
        <div className="App">
            <Contents
                setMessage={setMessage}
                page={page}
                settingsButtonOnClick={() => {
                    setPage("settings");
                }}
                backButtonOnClick={() => {
                    setPage("campaign");
                }}
            ></Contents>
            {message ? (
                <MessageComponent
                    message={message}
                    closeButtonOnClick={closeButtonOnClick}
                ></MessageComponent>
            ) : (
                ""
            )}
        </div>
    );
}

function MessageComponent(props) {
    return (
        <div className="message-box level__link">
            {props.message}
            <button className="close-button" onClick={props.closeButtonOnClick}>
                {" "}
                X{" "}
            </button>
        </div>
    );
}
function Contents(props) {
    if (props.page === "settings") {
        return (
            <SettingsComponent
                setMessage={props.setMessage}
                backButtonOnClick={props.backButtonOnClick}
            />
        );
    } else {
        return (
            <CampaignContents
                settingsButtonOnClick={props.settingsButtonOnClick}
                infoButtonOnClick={props.infoButtonOnClick}
            />
        );
    }
}

export default App;
