export const MOUSE_OVER_STATES = {
    NOTHING: 0,
    MOUSE_OVER: 1,
    MOUSE_DOWN: 2,
    MOUSE_UP: 3,
};

export const BOARD_TILES = {
    INVISIBLE_WALL: -3,
    WALL: -2,
    FREE: -1,
    P0_TARGET: 0,
    P1_TARGET: 1,
    P2_TARGET: 2,
    P3_TARGET: 3,
};
