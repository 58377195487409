import React, { useRef, useEffect } from "react";
import {
    getButtonRectangle,
    X_ALIGN,
    Y_ALIGN,
} from "../../utils/canvas_util.js";

import { GameState } from "./game_state.js";

function GameBoard(props) {
    const canvasRef = useRef(null);
    const gameStateRef = useRef(null);
    const windowRef = useRef(null);

    var lastTouchEventTime = 0;

    function mouseEventTranslate(e) {
        let boundingRect = canvasRef.current.getBoundingClientRect();
        let mouseX = e.clientX - boundingRect.x;
        let mouseY = e.clientY - boundingRect.y;
        return { x: mouseX, y: mouseY };
    }

    function touchEventTranslate(e) {
        let boundingRect = canvasRef.current.getBoundingClientRect();
        let touchobj = e.changedTouches[0];
        let mouseX = touchobj.pageX - boundingRect.x;
        let mouseY = touchobj.pageY - boundingRect.y;
        return { x: mouseX, y: mouseY };
    }

    function callMouseCallback(callbackId, eventArgs, translateFunc) {
        if (gameStateRef.current === null) return;
        let { x, y } = translateFunc(eventArgs);

        if (callbackId === "mousedown") {
            gameStateRef.current.mousedown(x, y);
        } else if (callbackId === "mouseup") {
            gameStateRef.current.mouseup(x, y);
        } else if (callbackId === "mousemove") {
            gameStateRef.current.mousemove(x, y);
        }
    }

    function callKeyDownCallback(eventArgs) {
        if (gameStateRef.current === null) return;
        gameStateRef.current.keydown(eventArgs);
    }

    function touchstart(e) {
        lastTouchEventTime = window.performance.now();
        callMouseCallback("mousedown", e, touchEventTranslate);
        e.preventDefault();
    }

    function touchmove(e) {
        lastTouchEventTime = window.performance.now();
        callMouseCallback("mousemove", e, touchEventTranslate);
        e.preventDefault();
    }

    function touchend(e) {
        lastTouchEventTime = window.performance.now();
        callMouseCallback("mouseup", e, touchEventTranslate);
        e.preventDefault();
    }

    function mousemove(e) {
        // if less than 0.1s from last touch event then ignore
        // it's because the touch event will also trigger a mouse event
        if (window.performance.now() - lastTouchEventTime < 100) {
            return;
        }
        callMouseCallback("mousemove", e, mouseEventTranslate);
    }

    function mouseup(e) {
        if (window.performance.now() - lastTouchEventTime < 100) {
            return;
        }
        callMouseCallback("mouseup", e, mouseEventTranslate);
    }

    function mousedown(e) {
        if (window.performance.now() - lastTouchEventTime < 100) {
            return;
        }
        callMouseCallback("mousedown", e, mouseEventTranslate);
    }

    const renderFrame = () => {
        if (gameStateRef.current === null) return;
        gameStateRef.current.draw(canvasRef.current);
    };

    var cnt_rendered_frames = 0;

    const updateFps = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        cnt_rendered_frames += 1;
        // var ncnt = 100;
        // if (i % ncnt === 0) {
        //     const p = window.performance.now();
        //     fps = (1000 * ncnt) / (p - last_time);
        //     last_time = p;
        //     // console.log("fps", i / 100, fps);
        // }

        const render_fps = true;
        if (render_fps) {
            const rect = getButtonRectangle(
                canvas,
                X_ALIGN.STRETCH,
                Y_ALIGN.BOTTOM
            );

            context.fillStyle = "white";
            context.textBaseline = "middle";
            context.font = `${canvas * 0.01}px Arial`;

            var text = "frame " + cnt_rendered_frames;

            context.fillText(
                text,
                rect.x + rect.width / 2 - context.measureText(text).width / 2,
                rect.y + rect.height / 2
            );

            //
        }
    };

    var total_frames = 0;
    const tick = () => {
        if (!canvasRef.current) return;
        if (gameStateRef.current === null) return;
        gameStateRef.current.update();
        total_frames += 1;
        if (gameStateRef.current.needsRender || total_frames < 100) {
            total_frames = 0;
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);
            renderFrame();
            // updateFps(); // only in debug!
        }
        requestAnimationFrame(tick);
    };

    function resize() {
        const game_state = gameStateRef.current;
        const canvas = canvasRef.current;
        if (game_state === null) return;
        if (canvas === null) return;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        game_state.recalcSizes(canvas);
    }

    useEffect(() => {
        requestAnimationFrame(tick);
        var game_state = new GameState();
        gameStateRef.current = game_state;
        resize();
        canvasRef.current.addEventListener("touchstart", touchstart, {});
        canvasRef.current.addEventListener("touchmove", touchmove, {});
        canvasRef.current.addEventListener("touchend", touchend, {});
        canvasRef.current.addEventListener("mousedown", mousedown);
        canvasRef.current.addEventListener("mouseup", mouseup);
        canvasRef.current.addEventListener("mousemove", mousemove);
        window.onresize = resize;
        gameStateRef.current.needsRender = true;
    }, []);

    useEffect(() => {
        if (windowRef.current == null) {
            windowRef.current = window;
            console.log("should happen only once");
            windowRef.current.addEventListener(
                "keydown",
                callKeyDownCallback,
                false
            );
        }
    }, []);

    useEffect(() => {
        if (gameStateRef.current) {
            gameStateRef.current.props = {
                onMenu: () => {
                    props.handleMenuClick();
                },
                onNextLevel: (level) => {
                    props.handleNextLevelClick(level);
                },
                onLevelComplete: (level, moves) => {
                    props.handleLevelComplete(level, moves);
                },
            };
        }
    }, [props]);

    useEffect(() => {
        gameStateRef.current.init(props.level, canvasRef.current);
        resize();
        gameStateRef.current.needsRender = true;
    }, [props.level]);

    return (
        <div className="wrapper">
            <canvas id="canvas-game" ref={canvasRef} tabIndex="0" />
        </div>
    );
}

export { GameBoard };
