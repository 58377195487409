//example line is
// b4c185146aa5a497c40ce35414b53b3dded3e1d1a1e9d787a22f1fd956e4fd73,
// 3 pieces; 6x4; 6x4_4,
// 6x4_4.2. ,
// 12,
// X X . bC B A ;. . . X . a ;. . . . c . ;. . X . . .
//a function that will split by a comma and create a dictionary with values
export function parseLevelCSVLine(line) {
    // if isn't a long string
    if (typeof line !== "string") {
        return null;
    }
    if (line.length < 64) {
        return null;
    }
    //split by comma
    let lineSplit = line.split(",");
    //create dictionary
    let level = {
        //add values to dictionary
        hash: lineSplit[0],
        path: lineSplit[1],
        name: lineSplit[2],
        opti: parseInt(lineSplit[3]),
        level: pad(lineSplit[4]),
    };
    if (level.hash.length != 64) {
        // throw exception
        throw "hash length is not 64";
    }
    return level;
}

function pad(level) {
    //split by ;
    let levelSplit = level.split(";");
    //find longest line
    let longestLine = 0;
    for (let i = 0; i < levelSplit.length; i++) {
        let iSplit = levelSplit[i].trim().split(" ");
        longestLine = Math.max(longestLine, iSplit.length);
    }

    let grid = Array.from(Array(levelSplit.length + 2), () =>
        Array.from(Array(longestLine + 2), () => "Y")
    );

    //function to convert all items adjacent either straight or diagonal on the grid to the same value
    function setX(grid, x, y) {
        if (grid[x][y] == "Y") {
            grid[x][y] = "X";
        }
    }
    function floodFill(grid, x, y) {
        if (grid[x][y] == "Y" || grid[x][y] == "X") {
            return;
        }
        setX(grid, x + 1, y);
        setX(grid, x - 1, y);
        setX(grid, x, y + 1);
        setX(grid, x, y - 1);
        setX(grid, x + 1, y + 1);
        setX(grid, x - 1, y - 1);
        setX(grid, x + 1, y - 1);
        setX(grid, x - 1, y + 1);
    }

    for (let x = 0; x < levelSplit.length; x++) {
        let iSplit = levelSplit[x].trim().split(" ");
        for (let y = 0; y < iSplit.length; y++) {
            grid[x + 1][y + 1] = iSplit[y];
        }
    }
    for (let x = 0; x < grid.length; x++) {
        for (let y = 0; y < grid[x].length; y++) {
            if (grid[x][y] != "Y" && grid[x][y] != "X") {
                floodFill(grid, x, y);
            }
        }
    }

    let newLevel = grid.map((row) => row.join(" ")).join(";");

    return newLevel;
}

export function getNumberBalls(levelDescription) {
    var balls = 0;
    if (levelDescription.includes("a")) {
        balls++;
    }
    if (levelDescription.includes("b")) {
        balls++;
    }
    if (levelDescription.includes("c")) {
        balls++;
    }
    return balls;
}
